import React from "react";
import "./overallprogress.scss";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const OverallProgress = ({ overallProgress }) => {
  return (
    <div className="overall-progress">
      <h4>Overall Progress</h4>
      <div className="progress-container">
        <CircularProgressbar
          strokeWidth={17.5}
          text={`${overallProgress}`}
          value={overallProgress}
          styles={buildStyles({
            textSize: "16px",
            pathColor: `#7ED957`,
            textColor: "#f88",
            trailColor: "#ff0000",
            backgroundColor: "#FF5757",
          })}
        />
      </div>
    </div>
  );
};

export default OverallProgress;
