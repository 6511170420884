import React from 'react';
import './assignmentcompleted.scss';
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const AssignmentCompleted = ({ assignmentCompleted }) => {

  return (
    <div className="assignment-completed">
      <div className="progress-container">
        <CircularProgressbar
          strokeWidth={17.5}
          text={`${assignmentCompleted}`}
          value={assignmentCompleted}
          styles={buildStyles({
            textSize: "16px",
            pathColor: `#8C52FF`,
            textColor: "#012970",
            trailColor: "#545454",
            backgroundColor: "#545454",
          })}
        />
      </div>
      <p>Assignment Completed</p>
    </div>
  );
};

export default AssignmentCompleted
