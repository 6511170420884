// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-bar-container {
  width: 100%;
  height: 10px;
  background-color: #545454;
  overflow: hidden;
  border-radius: 5px;
}
.progress-bar-container .progress-bar {
  height: 100%;
  background-color: #8c52ff;
}`, "",{"version":3,"sources":["webpack://./src/components/progressbar/progressbar.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,yBAAA;EACA,gBAAA;EACA,kBAAA;AACF;AACE;EACE,YAAA;EACA,yBAAA;AACJ","sourcesContent":[".progress-bar-container {\n  width: 100%;\n  height: 10px;\n  background-color: #545454;\n  overflow: hidden;\n  border-radius: 5px;\n\n  .progress-bar {\n    height: 100%;\n    background-color: #8c52ff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
