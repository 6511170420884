import React from "react";
import "./meter.scss";
import { PieChart, Pie, Cell } from "recharts";

const RADIAN = Math.PI / 180;

const data = [
  { name: "EASY", value: 33.33, color: "#00ff00" },
  { name: "MODERATE", value: 33.33, color: "#ff9900" },
  { name: "HARD", value: 33.33, color: "#ff0000" },
];
const cx = 100;
const cy = 100;
const iR = 30;
const oR = 60;

const calculateNeedleValue = (levelData) => {
  switch (levelData) {
    case "Easy":
      return 16.67;
    case "Moderate":
      return 50;
    case "Hard":
      return 83.33;
    default:
      return 0;
  }
};

const needle = (value, data, cx, cy, iR, oR, color) => {
  let total = 0;
  data.forEach((v) => {
    total += v.value;
  });
  const ang = 180.0 * (1 - value / total);
  const length = (iR + 2 * oR) / 3;
  const sin = Math.sin(-RADIAN * ang);
  const cos = Math.cos(-RADIAN * ang);
  const r = 5;
  const x0 = cx + 5;
  const y0 = cy + 5;
  const xba = x0 + r * sin;
  const yba = y0 - r * cos;
  const xbb = x0 - r * sin;
  const ybb = y0 + r * cos;
  const xp = x0 + length * cos;
  const yp = y0 + length * sin;

  return (
    <>
      <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" />
      <path
        d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`}
        stroke="none"
        fill={color}
      />
    </>
  );
};

const Meter = ({
  selectedCourseDuration,
  updatedAt,
  totalEmail,
  levelData,
}) => {
  const value = calculateNeedleValue(levelData);

  return (
    <div className="meter">
      <div className="chart">
        <PieChart width={200} height={200}>
          <Pie
            data={data}
            cx={cx}
            cy={cy}
            innerRadius={iR}
            outerRadius={oR}
            startAngle={180}
            endAngle={0}
            paddingAngle={5}
            dataKey="value"
            isAnimationActive={false}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          {needle(value, data, cx, cy, iR, oR, "#d0d000")}
        </PieChart>
      </div>
      <div className="info">
        <ul>
          <li>
            <p>
              Total Duration: <span>{selectedCourseDuration}</span>
            </p>
          </li>
          <li>
            <p>
              Last Updated On: <span>{updatedAt}</span>
            </p>
          </li>
          <li>
            <p>
              Total Students Enrolled: <span>{totalEmail}</span>
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Meter;
