import React from "react";
import "./duration.scss";
import ProgressBar from "../../progressbar/ProgressBar";

const Duration = ({ firstLoginTime, duration }) => {
  return (
    <div className="duration">
      <p>
        You enriolled this course on
        <br />
        <span>
          <b>{firstLoginTime}</b>
        </span>
      </p>
      <div className="progress-bar">
        <span>Chapter Duration</span>
        <ProgressBar percentage={duration} />
      </div>
    </div>
  );
};

export default Duration;
