import React, { useEffect, useState } from "react";
import "./chapterallocation.scss";
import PieChart from "./charts/PieChart";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";
import { useSelector } from "react-redux";

const ChapterAllocation = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/user-analytics/${currentUser.email}/count-chapters-per-course`
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data!", error);
      }
    };
    fetchData();
  }, [currentUser]);

  return (
    <div className="chapter-allocation">
      <h4>Chapter Allocation</h4>
      <div className="chart-container">
        <PieChart data={data} />
      </div>
    </div>
  );
};

export default ChapterAllocation;
