import React, { useEffect, useState } from "react";
import "./chapter.scss";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";
import { useSelector } from "react-redux";
import Heading from "../../components/chapter/heading/Heading";
import PromotionalVideo from "../../components/chapter/promotional-video/PromotionalVideo";
import CourseInfo from "../../components/chapter/course-info/CourseInfo";
import Duration from "../../components/chapter/duration/Duration";
import Progress from "../../components/chapter/progress/Progress";
import Swipper from "../../components/chapter/swipper/Swipper";
import Meter from "../../components/chapter/meter/Meter";
import { FaUserTie } from "react-icons/fa";

const Chapter = ({ courseName, chapterName }) => {
  const { currentUser } = useSelector((state) => state.user);
  const [data, setData] = useState(null);
  const [firstLoginTime, setFirstLoginTime] = useState("");
  const [duration, setDuration] = useState(0);
  const [levelData, setLevelData] = useState(null);
  const [totalEmail, setTotalEmail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/course/name/${courseName}`
        );
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [courseName]);

  useEffect(() => {
    const fetchFirstLoginTime = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/admin/${currentUser.email}/first-login-time`
        );
        if (response.data && response.data.firstLoginTime) {
          const firstLoginDateTime = new Date(response.data.firstLoginTime);
          const formattedDate = `${firstLoginDateTime.getFullYear()}-${String(
            firstLoginDateTime.getMonth() + 1
          ).padStart(2, "0")}-${String(firstLoginDateTime.getDate()).padStart(
            2,
            "0"
          )}`;
          setFirstLoginTime(formattedDate);
        } else {
          console.error("No firstLoginTime found in the response data");
        }
      } catch (error) {
        console.error("Error fetching first login time:", error);
      }
    };
    fetchFirstLoginTime();
  }, [currentUser]);

  useEffect(() => {
    const fetchDuration = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/course/${currentUser.email}/${courseName}/duration-passed`
        );
        setDuration(response.data.percentagePassed);
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchDuration();
  }, [currentUser, courseName]);

  useEffect(() => {
    const fetchCourseLevel = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/user-analytics/${courseName}/level`
        );
        setLevelData(response.data.level);
      } catch (error) {
        console.error("Error fetching course level:", error);
      }
    };
    fetchCourseLevel();
  }, [courseName]);

  useEffect(() => {
    const fetchTotalEmails = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/user-analytics/${courseName}/${chapterName}/emails`
        );
        setTotalEmail(response.data.count);
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchTotalEmails();
  }, [courseName, chapterName]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString();
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const { learningPoints } = data;

  return (
    <div className="chapter-container">
      <Heading courseName={courseName} chapterName={chapterName} />
      <div className="body">
        <div className="left">
          <div className="promotional-video">
            <PromotionalVideo />
          </div>
          <div className="intro">
            <CourseInfo data={data} />
          </div>
        </div>
        <div className="right">
          <div className="top">
            <div className="top-heading">
              <Duration firstLoginTime={firstLoginTime} duration={duration} />
              <div className="progress">
                <Progress duration={duration} />
              </div>
            </div>
          </div>
          <div className="middle">
            <h1>
              <span>Learning Points</span>
            </h1>
            <Swipper learningPoints={learningPoints} />
          </div>
          <div className="bottom">
            <Meter
              selectedCourseDuration={data && data.duration}
              updatedAt={formatDate(data.updatedAt)}
              totalEmail={totalEmail}
              levelData={levelData}
            />
            <p className="level">
              Level:{" "}
              <span
                className={
                  levelData && levelData === "Easy"
                    ? "color-green"
                    : levelData && levelData === "Moderate"
                    ? "color-orange"
                    : levelData && levelData === "Hard"
                    ? "color-red"
                    : ""
                }
              >
                {levelData}
              </span>
            </p>
            <div className="course">
              <h2>A Course By:</h2>
              <h1>
                <FaUserTie />
                {data.owner}
              </h1>
            </div>
            <div className="requirements">
              <h1>requirements:</h1>
              <p>{data.requirements}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chapter;
