import React from "react";
import "./remainingchapters.scss";
import { FaBookOpenReader } from "react-icons/fa6";

const RemainingChapters = ({ remainingChapters }) => {
  return (
    <div className="remaining-chapters">
      <h4>Remaining Chapters</h4>
      <FaBookOpenReader className="icon" />
      <span>{`${remainingChapters} Remaining Chapters`}</span>
    </div>
  );
};

export default RemainingChapters;
