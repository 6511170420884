import React, { useMemo } from "react";
import { ResponsiveBar } from "@nivo/bar";

const BarChart = ({ data }) => {
  // Preprocess data
  const processedData = useMemo(() => {
    // Get a unique list of all chapters across all courses
    const chapters = [
      ...new Set(
        data.flatMap((course) =>
          course.chapters.map((chapter) => chapter.chapter)
        )
      ),
    ];

    // Transform data into the format required by ResponsiveBar
    return data.map((course) => {
      const transformedCourse = { courseName: course.course };

      chapters.forEach((chapterName) => {
        const chapter = course.chapters.find(
          (chap) => chap.chapter === chapterName
        );
        transformedCourse[chapterName] = chapter
          ? chapter.assignmentPercentage
          : 0;
      });

      return transformedCourse;
    });
  }, [data]);

  // Get the unique chapter names to use as keys
  const keys = useMemo(() => {
    return [
      ...new Set(
        data.flatMap((course) =>
          course.chapters.map((chapter) => chapter.chapter)
        )
      ),
    ];
  }, [data]);

  // Function to format course names to first 4 letters
  const formatCourseName = (name) => {
    return name.length > 4 ? name.slice(0, 4) : name;
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <ResponsiveBar
        data={processedData}
        keys={keys}
        indexBy="courseName"
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={{ scheme: "nivo" }}
        borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendPosition: "middle",
          legendOffset: 32,
          format: (value) => formatCourseName(value), // Format course names
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendPosition: "middle",
          legendOffset: -40,
          truncateTickAt: 0,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        role="application"
        ariaLabel="Nivo bar chart demo"
        barAriaLabel={(e) =>
          `${e.id}: ${e.formattedValue} in course: ${e.indexValue}`
        }
      />
    </div>
  );
};

export default BarChart;
