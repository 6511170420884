import React, { useEffect, useState } from "react";
import "./assignmentprogress.scss";
import BarChart from "./charts/BarChart";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";
import { useSelector } from "react-redux";

const AssignmentProgress = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError("");
      try {
        const response = await axios.get(
          `${SERVER_URL}/user-analytics/assignment-percentages/${currentUser.email}`
        );
        setData(response.data);
      } catch (error) {
        setError("Error loading data!");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [currentUser]);

  const isNoDataAvailable = () => {
    return data.every((course) =>
      course.chapters.every((chapter) => chapter.assignmentPercentage === null)
    );
  };

  return (
    <div className="assignment-progress">
      <h4>Assignment Progress</h4>
      <div className="chart-container">
        {loading && <p>Loading...</p>}
        {error && <p className="error">{error}</p>}
        {isNoDataAvailable() ? (
          <p>No data available!</p>
        ) : (
          <BarChart data={data} />
        )}
      </div>
    </div>
  );
};

export default AssignmentProgress;
