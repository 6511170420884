import React from "react";
import "./totalchapters.scss";
import { FiBookOpen } from "react-icons/fi";

const TotalChapters = ({ totalChapters }) => {
  return (
    <div className="total-chapters">
      <h4>Total Chapters</h4>
      <FiBookOpen className="icon" />
      <span>{`${totalChapters} Chapters Assigned`}</span>
    </div>
  );
};

export default TotalChapters;
