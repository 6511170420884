import React from "react";
import WebGLLoader from "../../../WebGLLoader";

const PromotionalVideo = () => {
  return (
    <WebGLLoader />
  );
};

export default PromotionalVideo;
