// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-bars {
  display: flex;
  flex: 1 1;
  height: 100%;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  border-radius: 10px;
  padding: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/progressbars.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,YAAA;EACA,sBAAA;EACA,SAAA;EACA,8BAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AACF","sourcesContent":[".progress-bars {\n  display: flex;\n  flex: 1;\n  height: 100%;\n  flex-direction: column;\n  gap: 10px;\n  justify-content: space-between;\n  align-items: center;\n  background: transparent;\n  border-radius: 10px;\n  padding: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
