import React from 'react';
import "./totalcourses.scss";
import { FiBook } from "react-icons/fi";

const TotalCourses = ({total}) => {
  return (
    <div className="total-courses">
      <h4>Total Courses</h4>
      <FiBook className='icon' />
      <span>{`${total} Courses Assigned`}</span>
    </div>
  );
}

export default TotalCourses
