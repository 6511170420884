// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overall-progress {
  display: flex;
  flex: 1 1;
  height: 100%;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 20px;
}
.overall-progress h4 {
  text-transform: uppercase;
}
.overall-progress .progress-container {
  width: 150px;
  height: 150px;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/overallprogress.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,YAAA;EACA,sBAAA;EACA,SAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;EACA,4CAAA;EACA,aAAA;AACF;AACE;EACE,yBAAA;AACJ;AAEE;EACE,YAAA;EACA,aAAA;AAAJ","sourcesContent":[".overall-progress {\n  display: flex;\n  flex: 1;\n  height: 100%;\n  flex-direction: column;\n  gap: 10px;\n  justify-content: center;\n  align-items: center;\n  background: #fff;\n  border-radius: 10px;\n  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;\n  padding: 20px;\n\n  h4 {\n    text-transform: uppercase;\n  }\n\n  .progress-container {\n    width: 150px;\n    height: 150px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
