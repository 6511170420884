// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.assignment-completed {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.assignment-completed .progress-container {
  width: 80px;
  height: 80px;
}
.assignment-completed p {
  font-size: 9px;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/assignmentcompleted.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;EACA,SAAA;EACA,uBAAA;EACA,mBAAA;AACJ;AACI;EACI,WAAA;EACA,YAAA;AACR;AAEI;EACI,cAAA;AAAR","sourcesContent":[".assignment-completed{\n    display: flex;\n    flex-direction: column;\n    flex: 1;\n    gap: 10px;\n    justify-content: center;\n    align-items: center;\n\n    .progress-container{\n        width: 80px;\n        height: 80px;\n    }\n\n    p{\n        font-size: 9px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
