import React from 'react';
import "./activitycompleted.scss";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const ActivityCompleted = ({ activityCompleted }) => {

  return (
    <div className="activity-completed">
      <div className="progress-container">
        <CircularProgressbar
          strokeWidth={17.5}
          text={`${activityCompleted}`}
          value={activityCompleted}
          styles={buildStyles({
            textSize: "16px",
            pathColor: `#8C52FF`,
            textColor: "#012970",
            trailColor: "#545454",
            backgroundColor: "#545454",
          })}
        />
      </div>
      <p>Activity Completed</p>
    </div>
  );
};

export default ActivityCompleted
