import React, { useEffect, useState } from "react";
import "./progressbars.scss";
import ActivityCompleted from "./ActivityCompleted";
import AssignmentCompleted from "./AssignmentCompleted";
import ChapterCompleted from "./ChapterCompleted";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";
import { useSelector } from "react-redux";

const ProgressBars = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [chapterCompletion, setChapterCompletion] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/user-analytics/${currentUser.email}/chapter-completion-percentage`
        );
        setChapterCompletion(response.data.completionPercentage);
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchData();
  }, [currentUser]);

  return (
    <div className="progress-bars">
      <ActivityCompleted activityCompleted={chapterCompletion} />
      <AssignmentCompleted assignmentCompleted={chapterCompletion} />
      <ChapterCompleted completedChapters={chapterCompletion} />
    </div>
  );
};

export default ProgressBars;
